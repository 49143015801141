import React, { useState } from 'react'

import { BasicModal } from '@/components/layout/modals/BasicModal'

import { TaskOnboardingIntroduction } from './TaskOnboardingIntroduction'
import { TaskOnboardingAgentSelect } from './TaskOnboardingAgentSelect'

import './TaskOnboardingModal.scss'

export const TaskOnboardingModal = ({ inboxType, handleSubmitOnboarding }) => {
  const [step, setStep] = useState(1)

  return (
    <BasicModal
      data-testid="task-onboarding-modal"
      show
      size="tiny"
      showCloseButton={false}
      hideHeader
      canCloseOnDimmerClick={false}
    >
      {step === 1 && <TaskOnboardingIntroduction inboxType={inboxType} setStep={setStep} />}
      {step === 2 && <TaskOnboardingAgentSelect handleSubmitOnboarding={handleSubmitOnboarding} />}
    </BasicModal>
  )
}
